import React from "react";
import JSONData from "../../../content/content.json"
import parse from "html-react-parser";

let displayMenuOnMobile = false;

const openMobileMenu = () => {
  displayMenuOnMobile = true;
  handleDisplayOnMobileUpdate();
}

const closeMobileMenu = () => {
  displayMenuOnMobile = false;
  handleDisplayOnMobileUpdate();
}

const handleDisplayOnMobileUpdate = () => {
  setMenuDisplay();
  setIconsDisplay();
}

const setMenuDisplay = () => {
  const menu = document.getElementById('menu');
  if (displayMenuOnMobile) {
    menu.classList.remove('hide-on-mobile');
  } else {
    menu.classList.add('hide-on-mobile');
  }
}

const setIconsDisplay = () => {
  const openBtn = document.getElementById('open-mobile-menu-btn');
  const closeBtn = document.getElementById('close-mobile-menu-btn');
  if (displayMenuOnMobile) {
    openBtn.classList.add('display-none');
    closeBtn.classList.remove('display-none');
  } else {
    openBtn.classList.remove('display-none');
    closeBtn.classList.add('display-none');
  }
}

export default function Header() {
  return (
    <header className={'main-header'}>
      <div className={'main-header__wrapper'}>
        <div className="container">
          <nav className={'main-header__inner'}>
            <h1>
              <span className="visually-hidden">Limelight</span>
              <a href={'#intro'} className={'logo'}>
                <img src={'/limelight-logo.png'} alt={'Limelight Logo'}/>
              </a>
            </h1>

            <button id={'open-mobile-menu-btn'}
                    title={'Open mobile menu'}
                    onClick={openMobileMenu} className={'clear-button-style mobile-toggle-btn'}>
              <span className={'visually-hidden'}>Open mobile menu</span>
              <img src={'/icon-hamburger.png'} alt={'Open mobile menu'}/>
            </button>

            <button id={'close-mobile-menu-btn'}
                    title={'Close mobile menu'}
                    onClick={closeMobileMenu} className={'clear-button-style mobile-toggle-btn display-none'}>
              <span className={'visually-hidden'}>Close mobile menu</span>
              <img src={'/icon-close.png'} alt={'Close mobile menu'}/>
            </button>

            <ul id={'menu'} className={'links list-reset hide-on-mobile'}>
              {JSONData.menuItems.map((menuItem, index) => {
                return <li key={index}>
                  <a onClick={closeMobileMenu} href={`#${menuItem.anchor}`}>&lt;{parse(menuItem.label)}&gt;</a>
                </li>
              })}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}

import React from "react"
import "../../../node_modules/normalize.css/normalize.css";
import Header from "./header/header.js";
import Footer from "./footer/footer.js";


export default function Layout({children}) {
  return (
    <main>

      <title>Limelight Foundation</title>
      <Header/>
      {children}
      <Footer/>
    </main>
  )
}
